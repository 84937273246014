import {
  GameAlignment,
  GameAlignmentType,
  LineType,
  ScoreboardGameBasic,
  buildOdd,
} from "common";
import { TeamBasic } from "common";
import OddsCell, { OddCellColor } from "./cell";

export interface OddsRowProps {
  alignment: GameAlignment;
  hideLogo?: boolean;
  record?: boolean;
  color?: OddCellColor;
  game?: ScoreboardGameBasic;
}

export default function OddsRow(props: OddsRowProps) {
  const alignmentType = props.alignment === "away" ? GameAlignmentType.Away : GameAlignmentType.Home;
  const otherAlignmentType = props.alignment === "away" ? GameAlignmentType.Home : GameAlignmentType.Away;
  if (props.game) {
    const team: TeamBasic =
      props.alignment === "away" ? props.game.awayTeam : props.game.homeTeam;
    return (
      <>
        <div className="flex flex-row gap-1.5 justify-center items-center">
          {!props.hideLogo && (
            <>
              <div className="flex flex-col items-center gap-0.5 min-w-[36px]">
                <div className="w-4 h-4 flex justify-center items-center">
                  <img
                    src={team.logoUrl}
                    alt={`${team.location} ${team.name} Logo`}
                    className="w-full"
                  />
                </div>
                {props.record && (
                  // <div className="text-label-sm text-secondary">{props.alignment === "away" ? props.game.awayRecord.wins : props.game.homeRecord.wins}-{props.alignment === "away" ? props.game.awayRecord.losses : props.game.homeRecord.losses}</div>
                  <div className="text-label-sm text-secondary">
                    {props.game.league === "nhl" ? (
                      <>
                        {props.alignment === "away" ? (
                          <>
                            {props.game.awayRecord.wins}-
                            {props.game.awayRecord.losses}-
                            {props.game.awayRecord.overtimeLosses}
                          </>
                        ) : (
                          <>
                            {props.game.homeRecord.wins}-
                            {props.game.homeRecord.losses}-
                            {props.game.homeRecord.overtimeLosses}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {props.alignment === "away"
                          ? props.game.awayRecord.wins
                          : props.game.homeRecord.wins}
                        -
                        {props.alignment === "away"
                          ? props.game.awayRecord.losses
                          : props.game.homeRecord.losses}
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          <OddsCell
            alignment={props.alignment}
            game={props.game}
            color={props.color}
            odd={buildOdd(
              LineType.Moneyline,
              alignmentType,
              props.game.odds
            )}
            againstOdd={buildOdd(
              LineType.Moneyline,
              otherAlignmentType,
              props.game.odds
            )}
          />
          <OddsCell
            alignment={props.alignment}
            game={props.game}
            color={props.color}
            odd={buildOdd(
              LineType.Spread,
              alignmentType,
              props.game.odds
            )}
            againstOdd={buildOdd(
              LineType.Spread,
              otherAlignmentType,
              props.game.odds
            )}
          />

          <OddsCell
            alignment={props.alignment}
            game={props.game}
            color={props.color}
            odd={buildOdd(
              LineType.Total,
              alignmentType,
              props.game.odds
            )}
            againstOdd={buildOdd(
              LineType.Total,
              otherAlignmentType,
              props.game.odds
            )}
          />
        </div>
      </>
    );
  }
}
