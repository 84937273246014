/**
 * @returns a placeholder for values missing from the server.
 */
export function notApplicable(value: any): { label: string; value: any } {
  return {
    label: "N/A",
    value,
  };
}

/**
 * @returns a placeholder for empty states.
 */
export function empty(): string {
  return `- -`;
}

