"use client";
import React from "react";
import Select from "@/components/primitives/select";
import Icon from "@/components/primitives/icon";
import Button from "../primitives/button";
import {
  Injury,
  Sport,
  TeamBasic,
  formatEmptyState,
  injuryStatusColor,
} from "common";
import { ALL_SPORT_OPTIONS } from "@/data/sports/options";
import { useWebSocketMessage } from "@/utils/useWebSocketMessage";
import { formatDate } from "@/utils";
import NotFound from "../primitives/not-found";
import { LoadingCircle } from "../primitives/loading";
import { empty } from "@/utils/empty";
import useFetch from "@/utils/fetch";
import TeamDisplay from "../team/display";
import {
  createInjuriesSlug,
  createPlayerProfileSlug,
  createTeamSlug,
} from "@/utils/url";
import InjuryStatus from "../team/injury-status";
import { truncateName } from "@/utils/injuries";
import Link from "next/link";
import Card from "../primitives/card";
import Tooltip from "../primitives/tooltip";

interface LatestInjuriesProps {
  sport?: Sport;
  game?: {
    awayTeam: TeamBasic;
    homeTeam: TeamBasic;
  };
  team?: TeamBasic;
}

const cols = ["Player", "Team", "Date", "Status"];

export default function LatestInjuries(props: LatestInjuriesProps) {
  const [selectedSport, setSelectedSport] = React.useState<Sport | "all">(
    props.sport || "all"
  );
  const wsInjuries = useWebSocketMessage("injuries");
  // console.log("injuries from ws:", wsInjuries);
  const base_url: string = `${process.env.NEXT_PUBLIC_API_URL}/api/sports/data/injuries/${selectedSport}`;
  const params: URLSearchParams = new URLSearchParams();
  if (props.game) {
    params.append(
      "teamKey",
      `${props.game.awayTeam.abbreviation},${props.game.homeTeam.abbreviation}`
    );
  } else if (props.team) {
    params.append("teamKey", props.team.abbreviation);
  }
  // params.append("limit", "5");
  const request: string = `${base_url}?${params}`;
  const { data, isLoading, isError, error } = useFetch<Injury[] | null>(
    null,
    request,
    []
  );
  function groupInjuriesByTeam(
    data: Injury[] | null
  ): Record<string, Injury[]> {
    const groupedData: Record<string, Injury[]> =
      data?.reduce((acc, item) => {
        const teamKey = item.team?.abbreviation;
        if (!acc[teamKey]) {
          acc[teamKey] = [];
        }
        acc[teamKey].push(item);
        return acc;
      }, {} as Record<string, Injury[]>) || {};

    return groupedData;
  }
  // this is delayed from being client fetched. change in the future.
  const groupedData = groupInjuriesByTeam(data);
  // console.log("injuries for team: ", groupedData);
  return (
    <Card
      className={`${props.team || props.game ? "min-h-0" : "min-h-[368px]"}`}
    >
      {props.team && (
        <span className="text-label-sm-medium text-secondary uppercase">
          {props.team.name}
        </span>
      )}
      <div className="flex justify-between mb-2 gap-y-1 flex-col items-start min-[360px]:items-center min-[360px]:flex-row">
        <div className="flex items-center gap-1 whitespace-nowrap">
          <Icon name="cross" className="fill-error-300" size="md" />

          <div className="uppercase text-headline-bold text-secondary-300">
            {props.game ? "Team" : `${props.sport ?? ""} Latest`} Injuries
          </div>
        </div>
        <div className="flex items-center gap-1 shrink justify-end">
          {selectedSport !== "all" && (
            <Button href={createInjuriesSlug(selectedSport)} variant="card">
              View All
            </Button>
          )}
          {!props.sport && !props.team && (
            <div className="flex items-center gap-1">
              <Select
                label="Select Sport"
                options={ALL_SPORT_OPTIONS}
                defaultValue={ALL_SPORT_OPTIONS[0].value}
                variant="card"
                onValueChange={(v) => setSelectedSport(v as Sport | "all")}
              />
            </div>
          )}
        </div>
      </div>
      <div className="table-container">
        {isLoading ? (
          <div className="flex justify-center items-center h-[220px]">
            <LoadingCircle />
          </div>
        ) : !isLoading && (!data || !data.length) ? (
          <NotFound label="No injuries" icon="target-miss" />
        ) : (
          data && (
            <>
              {props.game && groupedData ? (
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col">
                    <TeamDisplay
                      sport={selectedSport}
                      variant="abbreviation"
                      size="sm"
                      team={props.game.awayTeam}
                    />
                    {groupedData[props.game.awayTeam.abbreviation] ? (
                      <InjuriesTable
                        data={groupedData[props.game.awayTeam.abbreviation]}
                      />
                    ) : (
                      <NotFound label="No injuries" />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <TeamDisplay
                      sport={selectedSport}
                      variant="abbreviation"
                      size="sm"
                      team={props.game.homeTeam}
                    />
                    {groupedData[props.game.homeTeam.abbreviation] ? (
                      <InjuriesTable
                        data={groupedData[props.game.homeTeam.abbreviation]}
                      />
                    ) : (
                      <NotFound label="No injuries" />
                    )}
                  </div>
                </div>
              ) : (
                <InjuriesTable
                  data={data.slice(0, 5)}
                  showSport={selectedSport === "all"}
                  showTeam={!props.team}
                />
              )}
            </>
          )
        )}
      </div>
    </Card>
  );
}

function InjuriesTable({
  data,
  showSport,
  showTeam,
}: {
  data: Injury[];
  showSport?: boolean;
  showTeam?: boolean;
}) {
  const [visibleCount, setVisibleCount] = React.useState(5);

  const handleViewMore = () => {
    const totalInjuries = data?.length;
    setVisibleCount(totalInjuries);
  };

  const handleViewLess = () => {
    setVisibleCount(5);
  };
  if (data) {
    const length = Math.min(visibleCount, data?.length || 0);
    const showViewMore = data.length > visibleCount;
    const showViewLess = visibleCount > 5;
    return (
      <>
        <table className="w-full text-left banded table-fixed">
          <thead>
            <tr className="text-label-sm-medium text-secondary-500 uppercase truncate">
              {cols.map((col, i) => {
                if (col !== "Team" || showTeam) {
                  return (
                    <th
                      key={i}
                      style={{
                        minWidth: `${
                          col === "Date" || col === "Status" ? "80px" : "50px"
                        }`,
                        width: `${
                          col === "Player"
                            ? "100%"
                            : col === "Date" || col === "Status"
                            ? "80px"
                            : "50px"
                        }`,
                        maxWidth: `${
                          col === "Date" || col === "Status" ? "80px" : "50px"
                        }`,
                      }}
                    >
                      {col === "Status" ? (
                        <div className="flex justify-end items-center">
                          {col}{" "}
                          <Tooltip>
                            <div className="p-4 text-left">
                              <div className="text-title-bold uppercase">
                                Injuries Status
                              </div>
                              <div className="text-caption mt-2">
                                Indicates the severity of a player's injury and
                                likeliness of being active in an upcoming match.
                              </div>
                              <div className="flex flex-col mt-4 gap-2  text-label-lg">
                              <div className="flex flex-row gap-2">
                                  <InjuryStatus status="Probable" side="left" />
                                  <span>Probable</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                  <InjuryStatus status="Questionable" side="left" />
                                  <span>Questionable</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                  <InjuryStatus status="Doubtful" side="left" />
                                  <span>Doubtful</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                  <InjuryStatus status="Out" side="left" />
                                  <span>Out</span>
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ) : col === "Position" || col === "Date" ? (
                        <div className="flex justify-center">{col}</div>
                      ) : (
                        col
                      )}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {Array.from({
              length,
            }).map((_, index) => {
              const injury = data?.[index];
              if (!injury) return null;
              return (
                <tr key={index}>
                  <td>
                    {injury.player ? (
                      <Link
                        href={createPlayerProfileSlug(
                          injury.sport,
                          injury.player.id
                        )}
                        className="flex flex-col gap-1"
                      >
                        <div className="flex flex-row gap-1 truncate">
                          <span className="truncate">
                            {truncateName(injury.player.firstName)}{" "}
                            {injury.player.lastName}
                          </span>
                          <span className="text-label-sm-medium text-secondary-500">
                            {injury.player.position}
                          </span>
                        </div>
                        <span className="text-label-lg-medium text-secondary-400">
                          {injury.bodyPart || empty()}
                        </span>
                      </Link>
                    ) : (
                      <div className="flex flex-col gap-1">N/A</div>
                    )}
                  </td>

                  {showTeam && (
                    <td>
                      <Link
                        href={createTeamSlug(injury.sport, injury.team)}
                        className="text-title-sm-medium flex justify-center items-center"
                      >
                        <div className="flex justify-center items-center w-6 h-6">
                          <img
                            src={injury.team.logoUrl}
                            alt={`${injury.team.name} Logo`}
                          />
                        </div>
                      </Link>
                    </td>
                  )}
                  <td>
                    <div className="text-title-sm-medium flex justify-center items-center">
                      {formatDate(injury.lastUpdated, false)}
                    </div>
                  </td>
                  <td>
                    <InjuryStatus status={injury.status} side="left" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-center items-center pt-4">
          {showViewMore && (
            <button
              onClick={handleViewMore}
              className="flex gap-0.5 text-label-lg-medium uppercase justify-center items-center"
            >
              <span>View More</span>
              <Icon name="caret-down" size="sm" />
            </button>
          )}
          {showViewLess && (
            <button
              onClick={handleViewLess}
              className="flex gap-0.5 text-label-lg-medium uppercase justify-center items-center"
            >
              <span>View Less</span>
              <Icon name="caret-up" size="sm" />
            </button>
          )}
        </div>
      </>
    );
  }
}

export function GameInjuriesTable({
  data,
  sport,
}: {
  data?: {
    away?: Injury[];
    home?: Injury[];
  };
  sport: Sport;
}) {
  const [visibleCount, setVisibleCount] = React.useState(5);

  const handleViewMore = () => {
    const totalInjuries = (data?.away?.length || 0) + (data?.home?.length || 0);
    setVisibleCount(totalInjuries);
  };

  const handleViewLess = () => {
    setVisibleCount(5);
  };

  if (data && (data.away || data.home)) {
    const length = Math.max(data.away?.length || 0, data.home?.length || 0);
    const showViewMore = length > visibleCount;
    const showViewLess = visibleCount > 5;
    return (
      <div className="bg-surface-700 p-2 rounded-br-md rounded-bl-md transition-all duration-300 ease-in-out">
        {Array.from({ length: Math.min(visibleCount, length) }).map(
          (_, index) => (
            <div
              key={index}
              className={`flex flex-row justify-between px-3 h-[46px] items-center rounded-md ${
                index % 2 === 1 ? "bg-surface-300" : ""
              }`}
            >
              {data.away?.[index] ? (
                <Link
                  href={createPlayerProfileSlug(
                    sport,
                    data.away[index].player.id
                  )}
                  className="flex flex-col gap-1 justify-center h-[46px] text-title-sm-medium"
                >
                  <div className="flex flex-row items-end w-full gap-1">
                    <span>
                      {truncateName(data.away[index].player.firstName)}{" "}
                      {data.away[index].player.lastName}
                    </span>
                    <div className="text-label-sm-medium text-secondary-500 justify-center">
                      {data.away[index].player.position}
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-1 w-full">
                    <div
                      className={`w-2 h-2 rounded-full ${injuryStatusColor(
                        data.away[index].status
                      )}`}
                    ></div>
                    <div className="text-label-lg-medium text-secondary-400">
                      {data.away[index].bodyPart}
                    </div>
                    <div className="w-px h-3 bg-secondary-700"></div>
                    <div className="text-label-lg-medium text-secondary-400">
                      {formatDate(data.away[index].lastUpdated)}
                    </div>
                  </div>
                </Link>
              ) : (
                <div className="text-label-sm-medium text-secondary-500 flex justify-center items-center">
                  {formatEmptyState("empty")}
                </div>
              )}
              {data.home?.[index] ? (
                <Link
                  href={createPlayerProfileSlug(
                    sport,
                    data.home[index].player.id
                  )}
                  className="flex flex-col gap-1 justify-center h-[46px] text-title-sm-medium"
                >
                  <div className="flex flex-row items-end w-full gap-1 justify-end">
                    <div className="text-label-sm-medium text-secondary-500 justify-center">
                      {data.home[index].player.position}
                    </div>
                    <span>
                      {truncateName(data.home[index].player.firstName)}{" "}
                      {data.home[index].player.lastName}
                    </span>
                  </div>
                  <div className="flex flex-row items-center gap-1 w-full justify-end">
                    <div className="text-label-lg-medium text-secondary-400">
                      {formatDate(data.home[index].lastUpdated)}
                    </div>
                    <div className="w-px h-3 bg-secondary-700"></div>
                    <div className="text-label-lg-medium text-secondary-400">
                      {data.home[index].bodyPart}
                    </div>
                    <div
                      className={`w-2 h-2 rounded-full ${injuryStatusColor(
                        data.home[index].status
                      )}`}
                    ></div>
                  </div>
                </Link>
              ) : (
                <div className="text-label-sm-medium text-secondary-500 flex justify-center items-center">
                  {formatEmptyState("empty")}
                </div>
              )}
            </div>
          )
        )}
        <div className="flex justify-center items-center">
          {showViewMore && (
            <button
              onClick={handleViewMore}
              className="flex gap-0.5 text-label-lg-medium uppercase justify-center items-center"
            >
              <span>View More</span>
              <Icon name="caret-down" size="sm" />
            </button>
          )}
          {showViewLess && (
            <button
              onClick={handleViewLess}
              className="flex gap-0.5 text-label-lg-medium uppercase justify-center items-center"
            >
              <span>View Less</span>
              <Icon name="caret-up" size="sm" />
            </button>
          )}
        </div>
      </div>
    );
  }
  return <NotFound label="No injuries found" />;
}
